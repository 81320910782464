import React, { useState, useEffect, useReducer, useCallback, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import ScheduleModal from "../../components/ScheduleModal";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../../components/ConfirmationModal";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import MainContainer from "../../components/MainContainer";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { Button, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { DeleteOutline, Edit, Search } from "@mui/icons-material";
import { format } from "date-fns";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { capitalizeFirstLowercaseRest } from "../../services/stringServices";


const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    const schedules = action.payload;
    const newSchedules = [];

    schedules.forEach((schedule) => {
      const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      if (scheduleIndex !== -1) {
        state[scheduleIndex] = schedule;
      } else {
        newSchedules.push(schedule);
      }
    });

    return [...state, ...newSchedules];
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === schedule.id);

    if (scheduleIndex !== -1) {
      state[scheduleIndex] = schedule;
      return [...state];
    } else {
      return [schedule, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    margin: theme.spacing(1),
    marginTop: 0,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    color: theme.palette.text.secondary
  },
  trashIcon: {
    color: theme.palette.secondary.main
  },
  tableCustomRow: {
    '& td, & th': {
      borderColor: theme.palette.divider,
    },
  },
}));

const Schedules = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [count, setCount] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
      const fetchSchedules = async () => {
        try {
          const { data } = await api.get("/schedules", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_SCHEDULES", payload: data.schedules });
          setCount(data.count);
          setHasMore(data.hasMore);
          setLoading(false);

        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchSchedules();
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("schedule", (data) => {
      if (data.action === "update" || data.action === "create" || data.action === "sent") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedule });

      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_SCHEDULE", payload: +data.scheduleId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
      setLoading(true);
    }
  };

  const handleOpenScheduleModal = () => {
    setScheduleModalOpen(true);
    setSelectedSchedule(null);
  }

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setSelectedSchedule(null);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setSelectedSchedule(null);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedSchedule(null);
  };

  const userCanView = (scheduleUser) => {
    const canView = Can({
      role: user.profile,
      perform: "drawer-admin-items:view",
      yes: () => true,
      no: () => false,
    });
    const userCanview = scheduleUser.id === user.id || canView ? true : false;
    return userCanview;
  }

  return (
    <>
      <ScheduleModal
        modalOpen={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        scheduleId={selectedSchedule?.id}
      />
      <ConfirmationModal
        title={
          selectedSchedule &&
          `${i18n.t("schedules.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteSchedule(selectedSchedule.id)}
      >

        {i18n.t("schedules.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <MainContainer>
        <MainHeader>
          <Title>{`${i18n.t("schedules.title")} (${count})`}</Title>
          <MainHeaderButtonsWrapper>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleOpenScheduleModal(true)}
            >
              {i18n.t("schedules.buttons.add")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableCustomRow}>
                <TableCell align="center">{i18n.t("schedules.table.user")}</TableCell>
                <TableCell align="center">{i18n.t("schedules.table.contact")}</TableCell>
                <TableCell align="center">{i18n.t("schedules.table.body")}</TableCell>
                <TableCell align="center">{i18n.t("schedules.table.sendAt")}</TableCell>
                <TableCell align="center">{i18n.t("schedules.table.status")}</TableCell>
                <TableCell align="center">{i18n.t("schedules.table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {schedules.map((schedule) => (
                  <>
                    {userCanView(schedule.user) &&
                      <TableRow className={classes.tableCustomRow} key={schedule.id}>
                        <TableCell align="center">{schedule.user?.name}</TableCell>
                        <TableCell align="center">{schedule.contact?.name}</TableCell>
                        <TableCell align="center">
                          <Tooltip title={<Typography>{schedule.message}</Typography>}>
                            <Typography
                              style={{ maxWidth: 300, align: "center" }}
                              noWrap
                              variant="body2"
                            >
                              {schedule.message}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          {format(new Date(schedule.sendAt), "dd/MM/yyyy HH:mm")}
                        </TableCell>
                        <TableCell align="center">{
                          schedule.status === 'sent' || schedule.status === 'pending' || schedule.status === 'error' ?
                            i18n.t(`schedules.table.statusTypes.${schedule.status}`) :
                            schedule.status
                        }</TableCell>
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            className={classes.iconButton}
                            disabled={schedule.status === 'sent'}
                            onClick={() => handleEditSchedule(schedule)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            className={classes.trashIcon}
                            onClick={(e) => {
                              setSelectedSchedule(schedule)
                              setConfirmModalOpen(true)
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    }
                  </>

                ))}
                {loading && <TableRowSkeleton columns={6} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    </>
  );
}

export default Schedules;
