import React from "react";
import Title from "../../components/Title";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useState, useEffect, useReducer, useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import MainContainer from "../../components/MainContainer";
import CampaignModal from "../../components/CampaignModal/index";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { Paper, Button, IconButton, Table, TableBody, TableHead, TableRow, TableCell, Tooltip, TextField, InputAdornment } from "@mui/material";
import { i18n } from "../../translate/i18n";
import { DeleteOutline, Edit, Search } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { getAppname } from "../../config";


const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    margin: theme.spacing(1),
    marginTop: 0,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    color: theme.palette.text.secondary
  },
  trashIcon: {
    color: theme.palette.secondary.main
  },
  tableCustomRow: {
    '& td, & th': {
      borderColor: theme.palette.divider,
    },
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_CAMPAIGNS") {
    const campaigns = action.payload;
    const newCampaigns = [];

    campaigns.forEach((campaign) => {
      const campaignIndex = state.findIndex((q) => q.id === campaign.id);
      if (campaignIndex !== -1) {
        state[campaignIndex] = campaign;
      } else {
        newCampaigns.push(campaign);
      }
    });

    return [...state, ...newCampaigns];
  }

  if (action.type === "UPDATE_CAMPAIGNS") {
    const campaign = action.payload;
    const campaignIndex = state.findIndex((u) => u.id === campaign.id);

    if (campaignIndex !== -1) {
      state[campaignIndex] = campaign;
      return [...state];
    } else {
      return [campaign, ...state];
    }
  }

  if (action.type === "DELETE_CAMPAIGN") {
    const campaignId = action.payload;
    const campaignIndex = state.findIndex((q) => q.id === campaignId);
    if (campaignIndex !== -1) {
      state.splice(campaignIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Campaigns = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [campaigns, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [campaignModal, setCampaignModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalCampaignOpen, setConfirmModalCampaignOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);


  useEffect(() => {
    setLoading(true);
      const fetchCampaigns = async () => {
        try {
          const { data } = await api.get("/campaigns", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CAMPAIGNS", payload: data.campaigns });
          setCount(data.count)
          setHasMore(data.hasMore);
          setLoading(false);

        } catch (err) {
          toastError(err);
          setLoading(false);
        }
      };
      fetchCampaigns();
  }, [searchParam, pageNumber]);


  useEffect(() => {
    const socket = openSocket();

    socket.on("campaign", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.campaign });

      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPAIGN", payload: data.campaignId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenCampaignModal = () => {
    setCampaignModalOpen(true);
    setSelectedCampaign(null);
  }
  const handleCloseCampaignModal = () => {
    setCampaignModalOpen(false);
    setSelectedCampaign(null);
  }
  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalOpen(true);
  };
  const handleSearch = (event) => {
    setSearchParam(event.target.value);
  };
  const handleDeleteCampaign = async (campaignId) => {
    try {
      await api.delete(`/campaigns/${campaignId}`);
      toast.success(i18n.t("campaigns.deleteConfirmationModal.deleteSuccess"));
    } catch (err) {
      toastError(err);
    }
    setSelectedCampaign(null);
  };
  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setConfirmModalCampaignOpen(false);
    setSelectedCampaign(null);
  };
  // const verifyCampaignsTime = () => {
  //   let campaignsList = campaigns.filter((campaign) => (new Date(campaign?.lastSent).getTime() + 10800000) > new Date().getTime())
  //   if (campaignsList.length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  const sendCampaignMessage = async (campaign) => {
    const { data } = await api.get(`/campaigns/${campaign.id}`);
    if (data.contacts.length > 0) {
      const lastSent = { lastSent: format(new Date(), "yyyy-MM-dd'T'HH:mm") };
      try {
        await api.put(`/campaigns/${campaign.id}/send`);
        await api.put(`/campaigns/${campaign.id}`, lastSent);
        toast.success(i18n.t("campaigns.sendConfirmationModal.sentSuccess"));
      } catch (err) {
        toastError(err);
      }
    } else {
      toast.error(i18n.t("campaigns.notices.noContacts"));
    }
    handleCloseConfirmationModal();
  }

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
      setLoading(true);
    }
  };
  
  if(getAppname() !== "sConversa")
  return (
    <Can
      role={user.profile}
      perform="drawer-admin-items:view"
      yes={() => (
        <>
          <ConfirmationModal
            title={
              selectedCampaign &&
              `${i18n.t("campaigns.sendConfirmationModal.title")} ${selectedCampaign.name
              }?`
            }
            open={confirmModalCampaignOpen}
            onClose={handleCloseConfirmationModal}
            onConfirm={() => sendCampaignMessage(selectedCampaign)}
          >
            {i18n.t("campaigns.sendConfirmationModal.warning")}
          </ConfirmationModal>
          <ConfirmationModal
            title={
              selectedCampaign &&
              `${i18n.t("campaigns.deleteConfirmationModal.title")} ${selectedCampaign.name
              }?`
            }
            open={confirmModalOpen}
            onClose={handleCloseConfirmationModal}
            onConfirm={() => handleDeleteCampaign(selectedCampaign.id)}
          >
            {i18n.t("campaigns.deleteConfirmationModal.warning")}
          </ConfirmationModal>
          <CampaignModal
            open={campaignModal}
            onClose={handleCloseCampaignModal}
            campaignId={selectedCampaign?.id}
          />
          <MainContainer>
            <MainHeader>
              <Title>{`${i18n.t("campaigns.title")} (${count})`}</Title>


              <MainHeaderButtonsWrapper>

                <TextField
                  placeholder={i18n.t("contacts.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenCampaignModal}
                >
                  {i18n.t("campaigns.buttons.create")}
                </Button>
              </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
              className={classes.mainPaper}
              variant="outlined"
              onScroll={handleScroll}
            >
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.tableCustomRow}>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.name")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.messages")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.sendAt")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.lastSent")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.status")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.contacts")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.connection")}
                    </TableCell>
                    <TableCell align="center">
                      {i18n.t("campaigns.table.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaigns.map((campaign) => (
                    <TableRow className={classes.tableCustomRow} key={campaign.id}>
                      <TableCell align="center">{campaign.name}</TableCell>
                      <TableCell align="center">
                        <div className={classes.customTableCell}>
                          <Tooltip title={
                            <React.Fragment>
                              <Typography>{`${i18n.t("campaigns.table.message")} 1:"${campaign.message}"`}</Typography>
                              <Typography>{`${i18n.t("campaigns.table.message")} 2:"${campaign.message2}"`}</Typography>
                              <Typography>{`${i18n.t("campaigns.table.message")} 3:"${campaign.message3}"`}</Typography>
                              <Typography>{`${i18n.t("campaigns.table.message")} 4:"${campaign.message4}"`}</Typography>
                              <Typography>{`${i18n.t("campaigns.table.message")} 5:"${campaign.message5}"`}</Typography>
                              <Typography>{`${i18n.t("campaigns.table.message")} 6:"${campaign.message6}"`}</Typography>
                            </React.Fragment>
                          }>
                            <Typography
                              style={{ width: 300, align: "center" }}
                              noWrap
                              variant="body2"
                            >
                              {
                               `${i18n.t("campaigns.table.message")} 1: "${campaign.message}";
                                ${i18n.t("campaigns.table.message")} 2: "${campaign.message2}";
                                ${i18n.t("campaigns.table.message")} 3: "${campaign.message3}";
                                ${i18n.t("campaigns.table.message")} 4: "${campaign.message4}";
                                ${i18n.t("campaigns.table.message")} 5: "${campaign.message5}";
                                ${i18n.t("campaigns.table.message")} 6: "${campaign.message6}";`
                              }
                            </Typography>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        {campaign.sendAt
                          ? format(new Date(campaign.sendAt), "dd/MM/yyyy HH:mm")
                          : i18n.t("campaigns.table.neverSent")}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.lastSent
                          ? format(new Date(campaign.lastSent), "dd/MM/yyyy HH:mm")
                          : i18n.t("campaigns.table.neverSent")}
                      </TableCell>
                      <TableCell align="center">
                        {i18n.t(`campaigns.table.statusTypes.${campaign.status}`)}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.contacts?.length}
                      </TableCell>
                      <TableCell align="center">
                        {campaign.whatsapp?.name}
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          size="small"
                          disabled={campaign.status === 'sending'}
                          className={classes.iconButton}
                          onClick={() => handleEditCampaign(campaign)}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          size="small"
                          className={classes.trashIcon}
                          onClick={() => {
                            setSelectedCampaign(campaign);
                            setConfirmModalOpen(true);
                          }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {loading && <TableRowSkeleton columns={8} />}
                </TableBody>
              </Table>
            </Paper>
          </MainContainer>
        </>
      )}
    />
  );
  else return null;
};

export default Campaigns;
