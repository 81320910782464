import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Divider from "@mui/material/Divider";
import { Badge, ListItemButton } from "@mui/material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventIcon from "@mui/icons-material/Event";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import makeStyles from '@mui/styles/makeStyles';
import { BookmarkBorderOutlined, Campaign, CampaignOutlined, ContactsOutlined, DeviceHubOutlined, FolderCopyOutlined, LocalOfferOutlined, PermMediaOutlined, StarHalfOutlined, Webhook, WhatsApp } from "@mui/icons-material";
import { matchPath, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getAppname } from "../config";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary
  },
  divider: {
    backgroundColor: theme.palette.divider,
  },

}));

function ListItemLink(props) {
  const { icon, primary, to, className } = props;
  const classes = useStyles();
  const location = useLocation();
  const isSelected =
    to === "/"
      ? location.pathname === "/"
      : !!matchPath(location.pathname, {
        path: to,
        exact: false,
      });

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItemButton
        component={renderLink}
        selected={isSelected}
        className={"mainListItems"}
      >
        {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose, open } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div onClick={drawerClose}>
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsApp />}
      />
      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactsOutlined />}
      />
      <ListItemLink
        to="/tags"
        primary={"Tags"}
        icon={<LocalOfferOutlined />}
      />
      <ListItemLink
        to="/categories"
        primary={i18n.t("mainDrawer.listItems.categories")}
        icon={<BookmarkBorderOutlined />}
      />
      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon />}
      />
      <ListItemLink
        to="/quickanswers"
        primary={i18n.t("mainDrawer.listItems.quickAnswers")}
        icon={<QuestionAnswerOutlinedIcon />}
      />
      <Divider className={classes.divider} />
      {open &&
        <ListSubheader inset>
          {i18n.t("mainDrawer.listItems.administration")}
        </ListSubheader>
      }
      <ListItemLink
        to="/"
        primary={i18n.t("mainDrawer.listItems.dashboard")}
        icon={<DashboardOutlinedIcon />}
      />
      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <ListItemLink
              to="/reviews"
              primary={i18n.t("mainDrawer.listItems.reviews")}
              icon={<StarHalfOutlined />}
            />
            <ListItemLink
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<PeopleAltOutlinedIcon fontSize="small" />}
            />
            {getAppname() !== "sConversa" &&
              <ListItemLink
                to="/campaigns"
                primary={i18n.t("mainDrawer.listItems.campaigns")}
                icon={<CampaignOutlined />}
              />
            }
            <ListItemLink
              to="/filelist"
              primary={i18n.t("mainDrawer.listItems.fileList")}
              icon={<FolderCopyOutlined />}
            />
            <ListItemLink
              to="/integrations"
              primary={i18n.t("mainDrawer.listItems.integrations")}
              icon={<DeviceHubOutlined />}
            />
            <ListItemLink
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<AccountTreeOutlinedIcon />}
            />
            {/*<ListItemLink
    
              to="/adminpanel"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<SettingsOutlinedIcon />}
            />*/}
          </>
        )}
      />
      <ListItemLink
        to="/connections"
        key={"connections"}
        index={"connections"}
        primary={i18n.t("mainDrawer.listItems.connections")}
        icon={
          <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
            <SyncAltIcon />
          </Badge>
        }
      />
      <ListItemLink
        to="/settings"
        primary={i18n.t("mainDrawer.listItems.settings")}
        icon={<SettingsOutlinedIcon />}
      />
    </div>
  );
};

export default MainListItems;
